import React from 'react'
import Spraycan from '../../img/spray.png'
import './map-element.css';
export default function MapElements() {
    return (
        <>
            <a href="#" >
                <img src={Spraycan} alt="spray-icon" id="vespucci_1" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_2" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_3" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_4" />
            </a>
            <a href="#">
                <img src={Spraycan} alt="spray-icon" id="vespucci_5" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_6" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_7" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_8" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_9" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_10" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_11" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_12" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_13" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_14" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_15" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_16" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_17" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_18" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_19" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_20" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_21" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_22" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_23" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_24" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_25" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_26" />
            </a>
            <a href="#" >
                <img src={Spraycan} alt="spray-icon" id="vespucci_27" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_28" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_29" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_30" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_31" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_32" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_33" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_34" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_35" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_36" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_37" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_38" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_39" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_40" />
            </a>
            <a href="#"   >
                <img src={Spraycan} alt="spray-icon" id="vespucci_41" />
            </a>
            <a href="#"  >
                <img src={Spraycan} alt="spray-icon" id="vespucci_42" />
            </a>
        </>
    )
};


