import React from 'react'

export default function Footer() {
    return (
        <>
        <footer className="absolute bot-0 right-0 left-0 px-4 py-2 bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700 mb-2 h-28 ">
            <p className="my-auto text-white text-center">Made with ❤ by Finn Westbrook(s1), Finn Finnsen(s4) and Benny Dry(s1)</p> 
        </footer>
        </>
    )
}
