import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../img/hogwarts.svg';


export default function Header() {
    return (
      <nav className="px-4 py-2 bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
      <a href="#" class="flex">
      <img src={Logo} width="51" height="70" className="mr-3 h-10" alt="hogwarts-logo" className="logo"></img>
      <span className="self-center text-lg font-semibold text-gray-900 whitespace-nowrap dark:text-white">Highstash</span>
      </a>
      <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
      <li>
      <Link to="/map" className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-white dark:bg-blue-600 md:dark:bg-transparent">Spraymap</Link>
      </li>
      </ul>
      </div>
      </nav>
    )
}


