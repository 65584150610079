import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Mods from './components/mods/mods';
import Map from './components/map/map';

ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<Map />} />
    {/* <Route path="/mods" element={<Mods />} /> */}
    <Route path="/map" element={<Map />} />
  </Routes>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
