import React from 'react'
import Header from '../header/header';
import MapBox from './mapBox';
import Footer from '../footer/footer';

export default function Map() {
    return (
        <div className="bg-slate-500">
            <Header /> 
            <MapBox />
            <Footer />
        </div>
    )
};


