import React from 'react'
import MapElements from './mapElements';
import './map.css';

export default function MapBox() {
    return (
        <>
           <div className='mapbackground'>
            <MapElements />
            </div>
        </>
    )
};


